import { Breadcrumb } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export default function Breadcrumbs() {
  const { pathname } = useLocation();

  const paths = [...new Set(pathname.split("/"))];

  const crumbs = paths.map((path, index, array) => {
    if (path === "") {
      return {
        name: "Home",
        url: "/",
        active: index === array.length - 1,
      };
    } else {
      const url = `/${array.slice(1, index + 1).join("/")}`;
      return {
        name: path,
        url,
        active: index === array.length - 1,
      };
    }
  });

  if (crumbs.length > 1) {
    return (
      <Breadcrumb className="px-2 m-0 border align-items-center my-breadcrumbs">
        {crumbs.map((e, index) => {
          return (
            <Breadcrumb.Item
              active={e.active}
              className="m-0"
              key={index}
              href={e.url}
            >
              {e.name}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  }
  return null;
}
