import { Button } from "react-bootstrap";

interface IconBtnProps {
  icon?: React.ReactNode;
  onClick: () => void;
  variant?: string | undefined;
  className?: string | undefined;
  children?: React.ReactNode;
  size?: "sm" | "lg" | undefined;
}

const IconBtn = ({
  icon,
  onClick,
  variant = "dark",
  className,
  children,
  size = "sm",
}: IconBtnProps) => {
  return (
    <Button
      className={
        className + " d-flex align-items-baseline justify-content-center"
      }
      size={size}
      variant={variant}
      onClick={onClick}
    >
      <span>{children}</span>
      {icon && <span className="ms-2">{icon}</span>}
    </Button>
  );
};

export default IconBtn;
