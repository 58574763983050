export const paymentMethods = [
  {
    text: "Bonifico bancario",
    id: 1,
  },
  {
    text: "Paypal",
    id: 3,
  },
  {
    text: "Carta di credito",
    id: 4,
  },
  {
    text: "Contanti",
    id: 5,
  },
];

export const getTextFromIdOfPaymentMethod = (id: number) => {
  return paymentMethods.find((el) => el.id === id)?.text || "n/a";
};
