import React from "react";
import { useSearchParams } from "react-router-dom";
import { Sun, Moon } from "react-feather";
import useSettingsState from "../../hooks/useSettingsState";

const ThemeSwitcher = () => {
  // let [searchParams, setSearchParams] = useSearchParams();

  const [theme, setTheme] = useSettingsState("theme", "light");

  let isDarkTheme = theme === "dark"; //searchParams.get("theme") === "dark";

  const changeTheme = () => {
    if (isDarkTheme) {
      setTheme("light");
      // setSearchParams({ theme: "default" });
      // window.location.reload();
    } else {
      setTheme("dark");
      // setSearchParams({ theme: "dark" });
      // window.location.reload();
    }
  };

  return (
    <div>
      <input
        checked={!isDarkTheme}
        onChange={changeTheme}
        type="checkbox"
        className="theme-checkbox"
        id="theme-checkbox"
      />
      <label htmlFor="theme-checkbox" className="theme-checkbox-label">
        <i className="fas fa-moon">
          <Moon size={16} />
        </i>
        <i className="fas fa-sun">
          <Sun size={16} />
        </i>
        <span className="theme-ball" />
      </label>
    </div>
  );
};

export default ThemeSwitcher;
