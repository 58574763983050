import PropTypes from "prop-types";

const Icon = ({ size = 46, color = "currentColor", ...props }) => (
  <svg
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M12 3.5c.091 0 .182.02.264.058l9.375 4.375a.625.625 0 0 1-.031 1.147l-.858.343v7.827A1.25 1.25 0 0 1 22 18.5V21h-3.75v-2.5a1.25 1.25 0 0 1 1.25-1.25V9.925l-7.267 2.905a.625.625 0 0 1-.465 0L2.393 9.08a.625.625 0 0 1-.031-1.147l9.375-4.375A.625.625 0 0 1 12 3.5ZM4.198 8.456 12 11.575l7.803-3.12L12 4.816l-7.802 3.64Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M6.968 12.25a.625.625 0 0 1 .252.04L12 14.083l4.78-1.793a.625.625 0 0 1 .82.409l.625 2.125a.625.625 0 0 1-.367.756l-5.625 2.25a.625.625 0 0 1-.465 0l-5.625-2.25a.625.625 0 0 1-.368-.756l.625-2.125a.624.624 0 0 1 .568-.448Zm.168 2.38L12 16.575l4.865-1.944-.275-.935-4.37 1.64a.625.625 0 0 1-.44 0l-4.37-1.64-.274.934Z"
      clipRule="evenodd"
    />
  </svg>
);

Icon.displayName = "Mortarboard";

Icon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

export default Icon;
