import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card, Carousel, Button } from "react-bootstrap";

import Header from "./Header";
import Appointments from "./Appointments";
import BarChart from "./BarChart";
import Calendar from "./Calendar";
import Feed from "./Feed";
import PieChart from "./PieChart";
import Projects from "./Projects";
import Statistics from "./Statistics";
import WorldMap from "./WorldMap";

const Default = () => (
  <React.Fragment>
    <Helmet title="Bentornato" />
    <Container fluid className="p-0">
      <Header />
      <NewProductsCarousel />
      <Statistics />
      <Row>
        <Col lg="8" className="d-flex">
          <BarChart />
        </Col>
        <Col lg="4" className="d-flex">
          <Feed />
        </Col>
      </Row>
      <Row>
        <Col lg="7" xl="8" className="d-flex">
          <WorldMap />
        </Col>
        <Col lg="5" xl="4" className="d-flex">
          <Calendar />
        </Col>
      </Row>
      {/* <Projects /> */}
    </Container>
  </React.Fragment>
);

export default Default;

const MockLogo = () => {
  return (
    <div
      style={{
        width: "80px",
        height: "80px",
        backgroundColor: "green",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.65rem",
        borderRadius: "100%",
      }}
    >
      logo compagnia
    </div>
  );
};

function NewProductsCarousel() {
  const newProducts = [
    {
      title: "Viaggio singolo",
      subtitle: "Compagnia IMA Italia Assistance",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: <MockLogo />,
    },
    {
      title: "D&O",
      subtitle: "Compagnia CHUBB",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: <MockLogo />,
    },
    {
      title: "Casa&Famiglia Smart",
      subtitle: "Compagnia Sace",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: <MockLogo />,
    },
    {
      title: "Fatturato Industria",
      subtitle: "Compagnia Siat JWT",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: <MockLogo />,
    },
    {
      title: "RCV+DPC",
      subtitle: "Compagnia Siat JWT",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: <MockLogo />,
    },
    {
      title: "BT PROTEZIONE Casa&Famiglia",
      subtitle: "Compagnia SACE",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: <MockLogo />,
    },
  ];
  return (
    <Row>
      <Card>
        <Card.Body className="">
          <Carousel
            className="new-product-carousel text-center  pb-5"
            indicators={true}
            controls={true}
            interval={5000}
          >
            {newProducts.map((product, index) => (
              <Carousel.Item key={index}>
                <Row
                  style={{ maxWidth: "600px" }}
                  className="py-2 mx-auto justify-content-center align-content-center"
                >
                  <Col
                    xs={12}
                    md={8}
                    className="text-center text-lg-start d-flex flex-column justify-content-between"
                  >
                    <h2>{product.title}</h2>
                    <h3>{product.subtitle}</h3>
                    <p>{product.desc}</p>
                  </Col>
                  <Col
                    xs={{ span: 12, order: "first" }}
                    md={{ span: 4, order: "last" }}
                    className="justify-content-between align-items-center pb-3 pb-lg-0 d-flex flex-column space-y-3"
                  >
                    {product.image}
                    <Button variant="primary" size="sm">
                      Fai un preventivo
                    </Button>
                  </Col>
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </Card.Body>
      </Card>
    </Row>
  );
}
