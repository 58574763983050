/**
 *  returns true if the value is null or undefined
 */
export default function func(value: any) {
  if (value) {
    if (typeof value === "string") {
      return !value.trim();
    }
    return !value;
  }
  return true;
}
