import React, { useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Row,
  Tooltip,
  Modal,
  OverlayTrigger,
  Spinner,
  Placeholder,
  InputGroup,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { format as dateFormat } from "date-fns"; //https://www.npmjs.com/package/dateformat
import { useSearchParams } from "react-router-dom";
import { formatInstallments } from "../../utils/helpers/formatInstallments";
import formatPrice from "../../utils/helpers/formatPrice";
import isNullOrEmpty from "../../utils/helpers/isNullOrEmpty";
import {
  CheckCircle,
  Repeat,
  XCircle,
  Check,
  Circle,
  ExternalLink,
  ThumbsUp,
  Send,
  RotateCcw,
} from "react-feather";
import { InstallmentType, QuotationType } from "../../types/_PagamentiTypes";
import { getTextFromIdOfPaymentMethod } from "../../utils/helpers/getPaymentMethodText";
import useWindowSize from "../../hooks/useWindowSize";
import NotyfContext from "../../contexts/NotyfContext";
import getBaseUrl from "../../utils/helpers/getBaseUrl";
import AuthGuard from "../../components/guards/AuthGuard";
import { useQuery } from "@tanstack/react-query";
import IconBtn from "../../components/IconBtn";

function useDettagliPolizza() {
  let [searchParams] = useSearchParams();
  const identifier = searchParams.get("identifier");
  const url = `${getBaseUrl()}/Api/Hub/GetQuotationByIdentifier?identifier=${identifier}`;
  return useQuery(
    ["dettagli-polizza"],
    async () => {
      const { data } = await axios.get(url);
      return data;
    },
    {
      retry: false,
    }
  );
}

function useInstallments() {
  let [searchParams] = useSearchParams();
  const identifier = searchParams.get("identifier");
  const url = `${getBaseUrl()}/Api/Hub/GetIntallmentsStatusByIdentifier?identifier=${identifier}`;

  const { status: QuotationStatus, data: Quotation } = useDettagliPolizza();

  return useQuery(
    ["installments"],
    async () => {
      const { data } = await axios.get(url);
      return data;
    },
    {
      enabled: QuotationStatus === "success" && Quotation?.Status >= 5,
    }
  );
}

const Pagamenti = () => {
  return (
    <AuthGuard>
      <PagamentiContent />
    </AuthGuard>
  );
};

const PagamentiContent = () => {
  let [searchParams] = useSearchParams();
  const identifier = searchParams.get("identifier");

  const [NotEmitted, setNotEmitted] = useState(false);

  const {
    status: QuotationStatus,
    data: Quotation,
    error: QuotationError,
    isFetching: QuotationFetching,
  } = useDettagliPolizza();

  const {
    status: InstallmentsStatus,
    data: Installments,
    error: InstallmentsError,
    isFetching: InstallmentsFetching,
  } = useInstallments();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [SelectedInstallment, setSelectedInstallment] =
    useState<InstallmentType | null>(null);

  const openModal = (installment: InstallmentType) => {
    setSelectedInstallment(installment);
    setIsOpen(true);
  };

  const mutateInstallments = () => {
    // setLoading(true);
    // setInstallments(null);
    console.log("mutateInstallments");
  };

  if (QuotationStatus === "error") {
    return <ErrorScreen identifier={identifier} />;
  }

  if (QuotationStatus === "loading") {
    return <LoadingScreen />;
  }
  return (
    <>
      <DettagliModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        Installment={SelectedInstallment}
      />
      <Helmet title="Pagamenti" />
      <Container fluid className="p-0">
        <h1 className="h4 mb-3">
          Bentornato <span className="text-primary"> Intermediario Test</span>,
          ecco il riepilogo pagamenti per la polizza n. {Quotation?.Id}:
        </h1>

        <Row className="pt-3">
          <Col xs={12} lg={4}>
            {Quotation && (
              <QuotationDetails
                Quotation={Quotation}
                mutateInstallments={mutateInstallments}
              />
            )}
          </Col>
          <Col xs={12} lg={8}>
            {NotEmitted ? (
              <Container fluid className="p-0">
                <h3 className="text-danger">
                  La polizza non risulta confermata
                </h3>
                <p className="text-lg">
                  Per procedere, torna alla{" "}
                  <u>
                    <a href="/#">lista attività</a>
                  </u>{" "}
                  e conferma per continuare.
                </p>
              </Container>
            ) : (
              <InstallmentsTable
                Loading={InstallmentsStatus === "loading"}
                Installments={formatInstallments(Installments)}
                openModal={openModal}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Pagamenti;

const LoadingInstallments = () => {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey={"0"}>
        <Placeholder as={Accordion.Header} animation="glow">
          <Placeholder size="lg" xs={12} />
        </Placeholder>
        <Placeholder as={Accordion.Body} animation="glow" className="space-y-3">
          <Placeholder size="lg" xs={12} />
          <Placeholder size="lg" xs={12} />
          <Placeholder size="lg" xs={12} />
        </Placeholder>
      </Accordion.Item>
    </Accordion>
  );
};

const LoadingScreen = () => {
  return (
    <>
      <Helmet title="Pagamenti" />
      <Container
        fluid
        className="p-0 h-100 d-flex justify-content-center align-items-center"
        // style={{ border: "2px", borderStyle: "solid", borderColor: "red" }}
      >
        <div className="text-center space-y-2">
          <div
            className="spinner-border custom-spinner"
            style={{ width: "5rem", height: "5rem" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
          <h1 className="text-5xl">Attendi</h1>
          <p className="text-lg">Caricamento in corso...</p>
        </div>
      </Container>
    </>
  );
};

const ErrorScreen = ({ identifier }: { identifier: string | null }) => {
  return (
    <>
      <Helmet title="Pagamenti" />
      <Container fluid className="p-0 h-100">
        <h1 className="text-3xl text-danger">Errore</h1>
        <p className="text-lg">
          Ci dispiace, non siamo riusciti a trovare le informazioni necessarie
          per la polizza "<span className="text-danger">{identifier}</span>
          ".
        </p>
      </Container>
    </>
  );
};

const InstallmentsTable = ({
  Loading,
  Installments,
  openModal,
}: {
  Loading: boolean;
  Installments: any;
  openModal: (installment: InstallmentType) => void;
}) => {
  if (Loading) return <LoadingInstallments />;

  return (
    <Accordion defaultActiveKey="0">
      {Installments.map((renew: any, index: number) => (
        <Accordion.Item key={index} eventKey={index.toString()}>
          <Accordion.Header>{renew.Title}</Accordion.Header>
          <Accordion.Body>
            <table className="table table-hover text-xs">
              <thead className="text-center">
                <tr>
                  <th scope="col"></th>
                  <th scope="col" className="d-none d-lg-table-cell">
                    #
                  </th>
                  <th scope="col">scadenza</th>
                  <th scope="col">importo rata</th>
                  <th scope="col">stato</th>
                  <th scope="col" className="d-none d-lg-table-cell">
                    quietanza
                  </th>
                  <th scope="col" className="d-none d-lg-table-cell">
                    -
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {renew.data.map((installment: any, index: number) => (
                  <tr
                    className="cursor-pointer"
                    key={index}
                    onClick={() => openModal(installment)}
                  >
                    <td className="col-1">
                      {installment.Payed ? (
                        <CheckCircle color="#70D296" />
                      ) : installment.isExpired ? (
                        <XCircle color="#DFA1A2" />
                      ) : installment.isExpiring ? (
                        // <AlertTriangle color="#E8C763" />
                        <Circle color="#a5b5b4" />
                      ) : null}
                    </td>
                    <td className="col-1 d-none d-lg-table-cell">
                      {index + 1}
                    </td>
                    <td className="col-3">
                      {dateFormat(new Date(installment.DueDate), "dd/MM/yyyy")}
                    </td>
                    <td className="col-2">
                      € {formatPrice(installment.Amount)}
                    </td>
                    <td className="col-2">
                      {installment.Payed ? (
                        <span className="text-success">pagata</span>
                      ) : installment.NotificationDate ? (
                        <OverlayTrigger
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip>
                              il:{" "}
                              {dateFormat(
                                new Date(installment.NotificationDate),
                                "dd/MM/yyyy"
                              )}
                            </Tooltip>
                          }
                        >
                          <span>notificata</span>
                        </OverlayTrigger>
                      ) : installment.isExpired ? (
                        <span className="text-danger">scaduta</span>
                      ) : installment.isExpired ? (
                        "in scadenza"
                      ) : (
                        "non pagata"
                      )}
                    </td>
                    <td className="col-2 d-none d-lg-table-cell">
                      {isNullOrEmpty(installment.QuietanzaUrl) ? (
                        <span className="">genera</span>
                      ) : (
                        <a href={installment.QuietanzaUrl}>visualizza</a>
                      )}
                    </td>
                    <td className="col-1 d-none d-lg-table-cell">
                      <span>
                        <u>dettagli</u>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const QuotationDetails = ({
  Quotation,
  mutateInstallments,
}: {
  Quotation: QuotationType;
  mutateInstallments: () => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [Loading, setLoading] = useState<boolean>(false);
  const notyf = React.useContext(NotyfContext);

  const baseUrl = getBaseUrl();

  const handleRinnovo = async () => {
    setLoading(true);
    if (Quotation.RenewTypology === 0) {
      //renew with new QuotationId & redirect
      let renewalNewQuotationUrl = `${baseUrl}/Api/Hub/RenewalNewPolicy?identifier=${Quotation.Identifier}`;
      axios(renewalNewQuotationUrl)
        .then((res) => {
          window.open(
            `https://${process.env.THIS_ENV}.crea-assicurazioni.com/Quotation/EditNew?version=3.0&id=${res.data}`,
            "_blank"
          );
        })
        .catch((err) => {
          console.log(err);
          notyf.open({
            type: "error",
            message: "Si è verificato un errore nell'elaborazione del rinnovo.",
            duration: 3000,
            ripple: true,
            dismissible: false,
            position: {
              x: "right",
              y: "center",
            },
          });
        })
        .then(() => {
          setIsOpen(false);
          setLoading(false);
        });
    } else if (Quotation.RenewTypology === 1) {
      //rinnovo by activity
      // const res = await renewQuotation(quotationId);
      let renewQuotationUrl = `${baseUrl}/Api/Hub/QuotationRenew?id=${Quotation.Id}`;

      axios(renewQuotationUrl)
        .then((res) => {
          mutateInstallments();
        })
        .catch((err) => {
          console.log(err);
          notyf.open({
            type: "error",
            message: "Si è verificato un errore nell'elaborazione del rinnovo.",
            duration: 3000,
            ripple: true,
            dismissible: false,
            position: {
              x: "right",
              y: "center",
            },
          });
        })
        .then(() => {
          setIsOpen(false);
          setLoading(false);
        });
    } else {
      // error
      alert("an error as occured: RenewTypology.notFound");
      // setIsLoading(false);
      // closeModal();
    }
  };
  return (
    <>
      <Modal
        centered
        size="sm"
        show={isOpen}
        onHide={() => {
          if (Loading) return;
          setIsOpen(false);
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body className="">
          <h4 className="mb-0">Vuoi davvero rinnovare la polizza?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-dark"
            disabled={Loading}
            onClick={() => setIsOpen(false)}
          >
            annulla
          </Button>{" "}
          <Button
            className="flex align-items-center space-x-2"
            variant="dark"
            onClick={() => {
              handleRinnovo();
            }}
            disabled={Loading}
          >
            <span className="text-white">Rinnova</span>
            {Loading ? (
              <Spinner className="text-white" animation="border" size="sm" />
            ) : (
              <Check className="text-white" size={14} />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <ul className="list-unstyled space-y-3 text-sm">
        <li>
          Premio Annuo: <b>€ {formatPrice(Quotation.PremioLordo)}</b>
        </li>
        <li>
          Polizza: <b>{Quotation.ProductName}</b>
        </li>
        <li>
          Valido dal: <b>25 Marzo 2022</b>
        </li>
        <li>
          Valido fino al: <b>25 Marzo 2023</b>
        </li>
        <li>
          Contraente:{" "}
          <b className="text-uppercase">
            {Quotation.AderenteCognome} {Quotation.AderenteNome}
          </b>
        </li>
      </ul>
      <div className="d-grid py-3 renew-button">
        <Button
          className="flex align-items-center space-x-2"
          variant="dark"
          onClick={() => setIsOpen(true)}
        >
          <span className="text-white">Rinnova polizza</span>
          <Repeat className="text-white" size={14} />
        </Button>
      </div>
    </>
  );
};

function DettagliModal({
  isOpen,
  setIsOpen,
  Installment,
}: {
  isOpen: boolean;
  setIsOpen: any;
  Installment: InstallmentType | null;
}) {
  const { width } = useWindowSize();
  const isMobile = width! <= 768;

  if (Installment) {
    return (
      <Modal
        fullscreen={isMobile ? true : "false"}
        centered
        // size="md"
        show={isOpen}
        onHide={() => setIsOpen(false)}
      >
        <Modal.Header closeButton>
          Dettagli pagamento #{Installment.Number}
          <small className="ms-1">
            {Installment.Renew && `(rinnovo ${Installment.Renew})`}
          </small>
        </Modal.Header>
        <Modal.Body className="text-center m-3 min-h-40vh">
          <div className="container">
            <div className="row text-start space-y-2">
              <div className="col-lg-6">
                Status:{" "}
                <b>
                  {Installment.Payed ? (
                    "pagata"
                  ) : Installment.NotificationDate ? (
                    <OverlayTrigger
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>
                          il:{" "}
                          {dateFormat(
                            new Date(Installment.NotificationDate),
                            "dd/MM/yyyy"
                          )}
                        </Tooltip>
                      }
                    >
                      <span>notificata</span>
                    </OverlayTrigger>
                  ) : (
                    "non pagata"
                  )}
                </b>
              </div>
              <div className="col-lg-6">
                Importo: <b>€ {formatPrice(Installment.Amount)}</b>
              </div>
              <div className="col-lg-6">
                Scadenza:{" "}
                <b>{dateFormat(new Date(Installment.DueDate), "dd/MM/yyyy")}</b>
              </div>
              <div className="col-lg-6">Quietanza: genera</div>
              {Installment.Payed && (
                <div className="col-lg-6">
                  Incassato da:{" "}
                  <b>
                    {Installment.PaymentCollector === 0
                      ? "Intermediario"
                      : "Crea"}
                  </b>
                </div>
              )}
              {Installment.Payed && (
                <div className="col-lg-6">
                  Pagato il:{" "}
                  <b>
                    {dateFormat(
                      new Date(Installment.PaymentDate),
                      "dd/MM/yyyy"
                    )}
                  </b>
                </div>
              )}
              {Installment.Payed && (
                <div className="col-lg-6">
                  Metodo di pagamento:{" "}
                  <b>
                    {getTextFromIdOfPaymentMethod(Installment.PaymentTypology)}
                  </b>
                </div>
              )}
            </div>
            <Accordion defaultActiveKey="0" className="py-3">
              <Accordion.Item eventKey={"0"}>
                <Accordion.Header>
                  Invia SMS al cliente per il pagamento
                </Accordion.Header>
                <Accordion.Body>
                  <div className="w-100 border border-dark p-2 rounded-2 bg-white text-start user-select-all">
                    <p>
                      <b>
                        Corrispondente Agente Demo | Servizi assicurativi
                        digitali
                      </b>
                    </p>
                    <p>
                      Gentile cliente puoi procedere al pagamento della tua
                      polizza seguendo il seguente link:
                    </p>
                    <p>
                      <i>
                        https://test.pagolapolizza.it/payment?identifier=Fp_l9RoP-vOsju&installmentId=10146
                      </i>
                    </p>
                  </div>
                  <InputGroup className="mt-3">
                    <Form.Control
                      size="lg"
                      placeholder="Numero di telefono cliente"
                      aria-label="Numero di telefono cliente"
                      aria-describedby="basic-addon2"
                      type="tel"
                    />
                    <IconBtn
                      onClick={() => console.log("click")}
                      icon={<Send size={16} />}
                      variant="secondary"
                      size="lg"
                    >
                      Invia
                    </IconBtn>
                  </InputGroup>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={"1"}>
                <Accordion.Header>
                  Invia Email al cliente per il pagamento
                </Accordion.Header>
                <Accordion.Body className="text-start">
                  <i>Più destinatari mail separati da ";"</i>
                  <Form>
                    <Form.Group className="my-3" controlId="formEmail">
                      <Form.Label>Indirizzo email</Form.Label>
                      <Form.Control type="email" placeholder="email" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formCC">
                      <Form.Label>CC</Form.Label>
                      <Form.Control type="email" placeholder="CC" />
                    </Form.Group>
                    <div className="w-100 border border-secondary p-2 rounded-2 bg-white text-start user-select-all">
                      <p>
                        <b>
                          Corrispondente Agente Demo | Servizi assicurativi
                          digitali
                        </b>
                      </p>
                      <p>
                        Gentile cliente puoi procedere al pagamento della tua
                        polizza seguendo il seguente link:
                      </p>
                      <p>
                        <i>
                          https://test.pagolapolizza.it/payment?identifier=Fp_l9RoP-vOsju&installmentId=10146
                        </i>
                      </p>
                    </div>
                    <div className="w-100 d-flex flex-column flex-lg-row gap-2 gap-lg-6 align-items-center justify-content-between mt-3">
                      <IconBtn
                        className="flex-grow-0 w-100"
                        onClick={() => console.log("click")}
                        icon={<RotateCcw size={16} />}
                        variant="outline-secondary"
                        size="lg"
                      >
                        reimposta
                      </IconBtn>
                      <IconBtn
                        className="flex-grow-1 w-100"
                        onClick={() => console.log("click")}
                        icon={<Send size={16} />}
                        variant="secondary"
                        size="lg"
                      >
                        Invia
                      </IconBtn>
                    </div>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ justifyContent: "space-between", textAlign: "center" }}
        >
          <IconBtn
            className="flex-grow-1"
            size="lg"
            icon={<ExternalLink size={16} />}
            variant="link"
            onClick={() => setIsOpen(false)}
          >
            Vai alla pagina di pagamento
          </IconBtn>
          <IconBtn
            className="flex-grow-1"
            size="lg"
            icon={<ThumbsUp size={16} />}
            variant="secondary"
            onClick={() => setIsOpen(false)}
          >
            Comunica incasso
          </IconBtn>
        </Modal.Footer>
      </Modal>
    );
  } else return null;
}
