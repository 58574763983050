import React from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
// import Settings from "../components/Settings";

import dashboardItems from "../components/sidebar/dashboardItems";
import Breadcrumbs from "../components/navbar/Breadcrumbs";
import { Helmet } from "react-helmet-async";
import useSidebar from "../hooks/useSidebar";
import { Fade } from "react-bootstrap";
import useWindowSize from "../hooks/useWindowSize";

const Dashboard: React.FC = ({ children }) => {
  const { productListOpen, setProductListOpen } = useSidebar();
  const lpWidth = 300;
  const { width } = useWindowSize();
  const isMobile = width! <= 768;
  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar items={dashboardItems} />
        <Main>
          {productListOpen && (
            <Fade
              in={productListOpen}
              appear
              children={
                <div
                  onMouseLeave={() => {
                    setProductListOpen(false);
                  }}
                  style={{
                    position: "fixed",
                    border: "1px solid rgba(251, 251, 251, 0.25)",
                    padding: 10,
                    color: "white",
                    zIndex: 500,
                    backgroundColor: "#354052",
                    borderRadius: "0 8px 8px 0",
                    width: lpWidth,
                    height: 600,
                    top: 65,
                    left: isMobile ? 40 : 260,
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      // border: "1px solid black",
                      zIndex: 50,
                      backgroundColor: "rgba(251, 251, 251, 0.05)",
                      width: 260,
                      height: 42,
                      top: 0,
                      right: lpWidth,
                    }}
                  />
                  content
                </div>
              }
            />
          )}
          <Navbar />
          <Breadcrumbs />
          <Content>
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
      {/* <Settings /> */}
      <HubspotChat />
    </React.Fragment>
  );
};

export default Dashboard;

const HubspotChat = () => {
  return (
    <Helmet>
      {/* Start of HubSpot Embed Code */}
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js-eu1.hs-scripts.com/26479074.js"
      />
      {/* End of HubSpot Embed Code */}
    </Helmet>
  );
};
