import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
// import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { ReactComponent as LogoCrea } from "../../assets/CreaCWhite.svg";

import { SidebarItemsType } from "../../types/sidebar";
import { Grid } from "react-feather";

interface SidebarProps {
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  open?: boolean;
  showFooter?: boolean;
}

const Sidebar = ({ items, showFooter = false }: SidebarProps) => {
  const { isOpen } = useSidebar();

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <LogoCrea /> <span className="align-middle me-3">Crea</span>
          </a>
          {/* <ul className="sidebar-nav">
            <li className="sidebar-item">
              <a href="/#" className="sidebar-link align-middle">
                <Grid className="feather" />
                <span className="align-middle" data-depth="0">
                  Lista Prodotti
                </span>
              </a>
            </li>
          </ul> */}

          <SidebarNav items={items} />
          {!!showFooter && <SidebarFooter />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
