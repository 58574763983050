/**
 * returns the base url of the application
 * @returns {string} - base url
 */

export default function getBaseUrl() {
  return process.env.NODE_ENV === "development"
    ? "https://localhost:44315"
    : `https://${process.env.THIS_ENV}.crea-assicurazioni.com`;
}
