import isEmptyObject from "./isEmptyObj";
import isNullOrEmpty from "./isNullOrEmpty";
import { format as dateFormat } from "date-fns";

export const formatInstallments = (installments: object[]) => {
  const checked = checkExpiring(installments);
  if (!checked) {
    return [];
  }

  if (checked.includes(undefined || null)) {
    return [];
  }

  const newArr: Array<object> = [];
  const wild = checked[0].Number > 1;

  if (wild) {
    return [
      {
        Title: "Rinnovi",
        data: checked,
        //.filter((inst) => inst.Renew === null || inst.Renew === 0),
      },
    ];
  }

  //todo: create type for this
  // console.log({ checked });
  checked.forEach((el: any, index: number, arr: any[]) => {
    if ((el.Renew === null || el.Renew === 0) && el.Number === 1) {
      newArr.push({
        Title: `Creazione polizza`,
        data: checked.filter(
          (inst: any) => inst.Renew === null || inst.Renew === 0
        ),
      });
    }

    if (el.Renew > 0 && el.Number === 1) {
      index = el.Renew;
      newArr.push({
        Title: `Rinnovo ${el.Renew}`,
        data: checked.filter((inst: any) => inst.Renew === index),
      });
    }
  });
  console.log({ newArr });
  newArr.forEach((renew: any) => {
    const startDate = dateFormat(new Date(renew.data[0].DueDate), "dd/MM/yyyy");
    const endDate = dateFormat(
      new Date(renew.data[renew.data.length - 1].DueDate),
      "dd/MM/yyyy"
    );
    renew.Title += ` (dal ${startDate} al ${endDate})`;
  });
  return newArr;
};

export const isExpired = (date: any) => {
  const today = new Date();
  return new Date(new Date(date).setDate(new Date(date).getDate() + 1)) < today;
};

export const isExpiringInAWeek = (date: any) => {
  const inSevenDays = new Date(new Date().setDate(new Date().getDate() + 7));
  return new Date(date) <= inSevenDays;
};

export const checkExpiring = (installments: any) => {
  if (!installments) return false;
  if (isEmptyObject(installments) || installments === undefined) return false;
  installments.forEach((e: any) => {
    e.isExpiring = false;
    e.isExpired = false;
    if (isNullOrEmpty(e)) {
      return false;
    } else {
      if ((isExpiringInAWeek(e.DueDate) || e?.NotificationDate) && !e.Payed) {
        e.isExpiring = true;
      }
      if (isExpired(e.DueDate) && !e.NotificationDate) {
        e.isExpired = true;
      }
    }
  });
  return installments;
};
