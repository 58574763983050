import React from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

const TestEmad = () => (
  <React.Fragment>
    <Helmet title="Test Page" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">
        Bentornato Agente Demo, ecco le coordinate bancarie di CREA
        Assicurazioni S.p.A.
      </h1>
      <Row>
        <Card>
          <Card.Header>
            <Card.Title></Card.Title>
            <h6 className="card-subtitle text-muted">aaa</h6>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2} className="text-sm-right">
                  Intestatario Conto corrente:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="corrente"
                    placeholder="CREA Assicurazioni S.p.A."
                  />
                </Col>
                <Col sm={2}>
                  <Button variant="outline-primary" className="me-1 mb-1">
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2} className="text-sm-right">
                  Presso:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="presso"
                    placeholder="Banca Sella S.p.A. Agenzia Milano Via Maurizio Gonzaga, 3 20123 - Milano"
                  />
                </Col>
                <Col sm={2}>
                  <Button variant="outline-primary" className="me-1 mb-1">
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2} className="text-sm-right">
                  Iban:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="iban"
                    placeholder="IT39P0326801603052661864711"
                  />
                </Col>
                <Col sm={2}>
                  <Button variant="outline-primary" className="me-1 mb-1">
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2} className="text-sm-right">
                  Valuota:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" name="valuta" placeholder="EURO" />
                </Col>
                <Col sm={2}>
                  <Button variant="outline-primary" className="me-1 mb-1">
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2} className="text-sm-right">
                  Conto Corrente:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="conto-orrente"
                    placeholder="052661864711"
                  />
                </Col>
                <Col sm={2}>
                  <Button variant="outline-primary" className="me-1 mb-1">
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2} className="text-sm-right">
                  ABI:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" name="abi" placeholder="03268" />
                </Col>
                <Col sm={2}>
                  <Button variant="outline-primary" className="me-1 mb-1">
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2} className="text-sm-right">
                  CAB:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" name="cab" placeholder="01603" />
                </Col>
                <Col sm={2}>
                  <Button variant="outline-primary" className="me-1 mb-1">
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2} className="text-sm-right">
                  BIC SWIFT:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="bic"
                    placeholder="SELBIT2BXXX"
                  />
                </Col>
                <Col sm={2}>
                  <Button variant="outline-primary" className="me-1 mb-1">
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  </React.Fragment>
);

export default TestEmad;
