import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs={12} md={8} className="text-center text-md-start">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="/#" className="text-muted">
                Assistenza
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/#" className="text-muted">
                Informativa privacy
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/#" className="text-muted">
                Termini del servizio
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/#" className="text-muted">
                Cookie policy
              </a>
            </li>
          </ul>
        </Col>
        <Col xs={12} md={4} className="text-center text-md-end">
          <p className="mb-0">
            &copy; 2020 -{" "}
            <span className="text-muted">CREA Assicurazioni S.p.A.</span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
