import { VectorMap } from "@react-jvectormap/core";
import {
  IAttributeSeries,
  ISVGElementStyleAttributes,
} from "@react-jvectormap/core/dist/types";
import { itRegionsMill } from "@react-jvectormap/italyregions";
import { useEffect } from "react";

import { Card, Dropdown } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";

import usePalette from "../../../hooks/usePalette";

function WorldMap() {
  const palette = usePalette();

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 250);
  }, [palette]);

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Download excell</Dropdown.Item>
              <Dropdown.Item disabled>Anno precedente</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">
          Distribuzione geografica dei premi
        </Card.Title>
      </Card.Header>
      <Card.Body className="p-2">
        <div className="map-container" style={{ height: "100%" }}>
          <VectorMap
            map={itRegionsMill}
            zoomMax={6}
            series={{
              markers: [
                {
                  attribute: "fill",
                  scale: ["#C8EEFF", "#0071A4"],
                  normalizeFunction: "polynomial",
                  values: [408, 512, 550, 781],
                  legend: {
                    vertical: false,
                  },
                } as IAttributeSeries,
              ],
            }}
            backgroundColor="transparent"
            regionStyle={{
              initial: {
                fill: palette["gray-200"],
                stroke: palette.black,
                "stroke-width": 1,
                "stroke-opacity": 0.5,
              } as ISVGElementStyleAttributes["initial"],
              hover: {
                fill: palette.info,
                cursor: "pointer",
              },
              selected: {
                fill: "yellow",
              },
              selectedHover: {},
            }}
            markers={[
              {
                latLng: [45.4642, 9.1895],
                name: "Milano",
              },
              {
                latLng: [44.4056, 8.9463],
                name: "Genova",
              },
              {
                latLng: [38.1157, 13.3613],
                name: "Palermo",
              },
              {
                latLng: [41.9028, 12.4964],
                name: "Roma",
              },
            ]}
          />
        </div>
      </Card.Body>
    </Card>
  );
}

export default WorldMap;
