import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isAuthenticated, isInitialized } = useAuth();
  const location = useLocation();

  if (isInitialized && !isAuthenticated) {
    return (
      <Navigate
        to={`/auth/sign-in?callback=${location.pathname}${location.search}`}
      />
    );
  }

  if (isAuthenticated) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <>loading</>;
}

export default AuthGuard;
