import React from "react";

import SidebarNavSection from "./SidebarNavSection";
import { SidebarItemsType } from "../../types/sidebar";
import { ChevronRight, Grid } from "react-feather";
import { ButtonGroup, DropdownButton, ListGroupItem } from "react-bootstrap";
import useSidebar from "../../hooks/useSidebar";

interface SidebarNavProps {
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
}

const SidebarNav = ({ items }: SidebarNavProps) => {
  return (
    <ul className="sidebar-nav">
      <ListaProdotti />
      {items &&
        items.map((item) => (
          <SidebarNavSection
            key={item.title}
            pages={item.pages}
            title={item.title}
          />
        ))}
    </ul>
  );
};

export default SidebarNav;

function ListaProdotti() {
  const { productListOpen, setProductListOpen } = useSidebar();

  return (
    <li
      onMouseEnter={() => {
        setProductListOpen(true);
      }}
      className="sidebar-item"
    >
      <div className="sidebar-link align-middle">
        <Grid className="feather" />
        <span className="align-middle" data-depth="0">
          Lista Prodotti
        </span>
        <ChevronRight
          className="feather"
          style={{
            position: "absolute",
            right: 6,
          }}
        />
      </div>
    </li>
  );
}
