import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { PieChart, Settings, User } from "react-feather";

import avatar1 from "../../assets/img/avatars/avatar.jpg";
import ThemeSwitcher from "../theme/ThemeSwitcher";

const NavbarUser = () => {
  return (
    <Dropdown className="nav-item custom-user-dropdown-color" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          {/* <img
            src={avatar1}
            className="avatar img-fluid rounded-circle me-1"
            alt="Chris Wood"
          /> */}
          <span>Utente test</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu>
        <Dropdown.Item>
          <User size={18} className="align-middle me-2" />
          Profile
        </Dropdown.Item>
        <Dropdown.Item>
          <PieChart size={18} className="align-middle me-2" />
          Analytics
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item>Settings & Privacy</Dropdown.Item>
        <Dropdown.Item>Help</Dropdown.Item>
        <Dropdown.Item onClick={() => console.log("sign out")}>
          Sign out
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.ItemText className="d-flex align-items-center justify-content-center text-dark">
          <ThemeSwitcher />
        </Dropdown.ItemText>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
