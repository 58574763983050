import React from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import NotyfContext from "../../contexts/NotyfContext";

const CoordinateBancarie = () => {
  const elements = [
    {
      label: "Intestatario Conto corrente:",
      content: "CREA Assicurazioni S.p.A.",
    },
    {
      label: "Presso:",
      content:
        "Banca Sella S.p.A. Agenzia Milano Via Maurizio Gonzaga, 3 20123 - Milano",
    },
    {
      label: "Iban:",
      content: "IT39P0326801603052661864711",
    },
    {
      label: "Valuta:",
      content: "EURO",
    },
    {
      label: "Conto Corrente:",
      content: "052661864711",
    },
    {
      label: "Codice ABI:",
      content: "03268",
    },
    {
      label: "Codice CAB:",
      content: "01603",
    },
    {
      label: "BIC SWIFT:",
      content: "SELBIT2BXXX",
    },
  ];

  const notyf = React.useContext(NotyfContext);

  const showNotification = () => {
    notyf.open({
      type: "success",
      message: "copiato!",
      duration: 2000,
      ripple: true,
      dismissible: false,
      position: {
        x: "right",
        y: "center",
      },
      // className: "bottom-80px",
    });
  };

  return (
    <React.Fragment>
      <Helmet title="Test Page" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">
          Bentornato Agente Demo, ecco le coordinate bancarie di CREA
          Assicurazioni S.p.A.
        </h1>
        <Card>
          <Card.Body className="space-y-3">
            {elements.map((el, index) => {
              return (
                <Row key={index} className="align-items-center">
                  <Col xs={12} lg={3} xl={3} className="text-sm-right">
                    {el.label}
                  </Col>
                  <Col
                    xs={10}
                    lg={8}
                    xl={6}
                    className="user-select-all border d-flex align-items-center py-1"
                  >
                    {el.content}
                  </Col>
                  <Col xs={1}>
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(el.content);
                        showNotification();
                      }}
                      variant="outline-dark"
                      className="me-1 mb-1"
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default CoordinateBancarie;
